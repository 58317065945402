import React from 'react'
import WithPrivateRoute from '../../../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../../../components/SEO'
import ReportContainerByDayTickets from '../../../../containers/ReportContainerByDayTickets'
import LayoutMain from '../../../../layout'

//ReportDetailsByDay
const DetailsByDayTickets: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Tickets por día" />
      <WithPrivateRoute component={ReportContainerByDayTickets} />
    </LayoutMain>
  )
}

export default DetailsByDayTickets
