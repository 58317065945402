import moment from 'moment'
import { ReportDayTicketsColumns } from '../../components/ReportDayTicketsColumns'

import { ReportMonthsResume } from '../../components/ReportMonthsResume'
import { TableGridProps } from '../../components/Storybook/TableGrid'
import { TileRowsProps } from '../../components/Storybook/TileRows'
import {
  QueryReportDayTicktesDetailSummary,
  QueryReportGetTicketList,
  TicketListSummary
} from '../../types/Reports/ReporDayTickets'
import { QueryReportSummarySales } from '../../types/Reports/ReportMonths'
import { capitalizeFirstsLettersOfString } from '../../utils/general'
import { getsummaryLabel } from '../FilterSalesReportContainer/utils'

interface TransformQueryToDataTableGridProps {
  tileRow: TileRowsProps
  tableGrid: TableGridProps
}

const transformQueryToDataTableGrid = (
  detailSummary: QueryReportDayTicktesDetailSummary[]
): TableGridProps => {
  const dataTable: any = detailSummary
  const columns = ReportDayTicketsColumns
  const tableGrid = {
    data: dataTable,
    columns,
    isHeader: false,
    isVerticalLine: false
  }
  return tableGrid
}

export const transformQueryTypeDayTickets = (
  ticketListSummary: TicketListSummary
): QueryReportSummarySales => {
  const { fromDate } = ticketListSummary
  const date = fromDate.split('T')[0]
  const dateFormat = moment(date).format('dddd D MMMM ')
  const dateFormatUpperLetter = capitalizeFirstsLettersOfString(dateFormat)
  const summarySales = {
    fromTitleDetail: dateFormatUpperLetter,
    toTitleDetail: '',
    totalCash: ticketListSummary.cashAmount,
    totalCard: ticketListSummary.cardAmount,
    totalClubModelorama: ticketListSummary.loyaltyAmount,
    totalSales: ticketListSummary.totalAmount,
    totalNumberOperations: ticketListSummary.totalCounter
  }

  return summarySales
}

export const transformQueryReportToDataComponents = (
  queryData: QueryReportGetTicketList,
  view: string,
  storeId: string
): TransformQueryToDataTableGridProps => {
  const { getTicketList } = queryData

  const { ticketListItems: detailSummary, ticketListSummary } = getTicketList
  const tableGrid = transformQueryToDataTableGrid(detailSummary)

  const summarySales = transformQueryTypeDayTickets(ticketListSummary)
  const titleDate = getsummaryLabel(
    ticketListSummary.fromDate.split('T')[0].replaceAll('-', '')
  )

  const tileRow = ReportMonthsResume(summarySales, view, titleDate, '', storeId)

  return { tileRow, tableGrid }
}
