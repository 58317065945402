import React, { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'

import ReportContent from '../../components/ReportContent'

import { GET_DAY_TICKETS } from '../../graphql/queries/Reports'
import { transformQueryReportToDataComponents } from './utils'
import { getParameter } from '../../utils/queryString'

const ReportContainerByDayTickets: React.FC = () => {
  const storeId = getParameter('storeId') ? getParameter('storeId') : ''
  const from = getParameter('from')
  const userId = getParameter('userId')
  let tileRow: any = {
    rowBody: [],
    rowHeader: { parameter: '', value: '' }
  }
  let tableGrid: any = {
    data: [],
    columns: [],
    isHeader: false
  }

  const [
    refetch,
    {
      data: dataStoreDayTickets,
      loading: loadingStoreDayTickets,
      error: errorStoreDayTickets
    }
  ] = useLazyQuery(GET_DAY_TICKETS, {
    context: {
      clientName: 'api-instore'
    }
  })

  if (
    dataStoreDayTickets &&
    !loadingStoreDayTickets &&
    !errorStoreDayTickets &&
    storeId
  ) {
    const { tileRow: tileRowTransformed, tableGrid: tableGridTransformed } =
      transformQueryReportToDataComponents(dataStoreDayTickets, 'days', storeId)
    tileRow = tileRowTransformed
    tableGrid = tableGridTransformed
  }

  useEffect(() => {
    if (from && storeId) {
      const variables = { from, storeId, userId }
      refetch({
        variables
      })
    }
  }, [from, storeId])

  return (
    <ReportContent
      tileRow={tileRow}
      tableGrid={tableGrid}
      loading={loadingStoreDayTickets}
      view="day"
      storeID={storeId ? storeId : ''}
    />
  )
}

export default ReportContainerByDayTickets
